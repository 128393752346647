/* eslint-disable react/jsx-no-target-blank */
import React, { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from '@reach/router';
import Logo from '@freska-fi/ui-logo';
import Box from '@freska-fi/ui-box';
import Link from '@freska-fi/ui-link';
import LocalePicker from '@freska-fi/ui-locale-picker';

import config from '../../config';
import { changeLocale } from '../../store/locale/actions';
import { getLocale } from '../../store/locale/selectors';
import { Locale } from '../../store/locale/types';
import { Dispatch } from '../../store/types';
import { termsAndConditionsUrl, privacyPolicyUrl } from '../../utilities/urls';
import { getBrand } from '../../utilities/brand';
import { KpLogo } from '../KpLogo/KpLogo';

export interface LayoutProps extends RouteComponentProps {
  children: ReactNode;
}

export default function Layout({ children }: LayoutProps) {
  const dispatch = useDispatch<Dispatch>();
  const locale = useSelector(getLocale);

  return (
    <Box display="flex" justifyContent="space-between" flexDirection="column" minHeight="100vh" width="100%">
      <Box position="absolute" mr={[2, 2, 'auto']} pl={3} py={3} minWidth={128}>
        <a href={config.siteUrl} target="_blank">
          {getBrand() === 'kp' ? <KpLogo size={48} /> : <Logo size={24} />}
        </a>
      </Box>

      <Box
        sx={{ fontSize: theme => theme.typography.body2.fontSize }}
        position="absolute"
        ml={[2, 2, 'auto']}
        pr={3}
        py={3}
        minWidth={128}
        right={0}
        textAlign="right"
      >
        <LocalePicker
          locales={[config.defaultLocale, 'en']}
          value={locale as string}
          onChange={value => dispatch(changeLocale(value as Locale))}
        />
      </Box>

      <Box display="flex" minHeight="calc(100vh - 48px)">
        {children}
      </Box>

      <Box display="flex" alignItems="center" justifyContent={['center', 'center', 'flex-end']} height={48} px={3}>
        <Box display="inline-block" mr={2}>
          <Link
            color="text.secondary"
            variant="caption"
            underline="always"
            href={termsAndConditionsUrl(locale as string)}
            target="_blank"
          >
            <FormattedMessage id="Terms and conditions" />
          </Link>
        </Box>
        <Box display="inline-block">
          <Link
            color="text.secondary"
            variant="caption"
            underline="always"
            href={privacyPolicyUrl(locale as string)}
            target="_blank"
          >
            <FormattedMessage id="Privacy policy" />
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
