import { Locale } from '../store/locale/types';
import { getBrand } from '../utilities/brand';

import fi from './fi';
import fiKp from './fi-kp';
import no from './no';
import se from './se';

export interface Config {
  countryCode: string;
  defaultLocale: Locale;
  currencySign: string;
  checkoutUrl: string;
  siteUrl: string;
  appStoreUrl: string;
  googlePlayUrl: string;
  googleReviewUrl: string;
  termsAndConditionsUrl: Record<string, string>;
  privacyPolicyUrl: Record<string, string>;
  freskaAppUrl: Record<string, string>;
}

function resolveSiteConfig(): Config {
  switch (process.env.REACT_APP_COUNTRY) {
    case 'no':
      return no;
    case 'se':
      return se;
    case 'fi':
    default:
      return getBrand() === 'kp' ? fiKp : fi;
  }
}

export default resolveSiteConfig();
