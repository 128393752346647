import { Config } from './index';

const config: Config = {
  countryCode: 'fi',
  defaultLocale: 'fi',
  currencySign: '€',
  checkoutUrl: 'https://tilaus.kotipuhtaaksi.fi',
  siteUrl: 'https://www.kotipuhtaaksi.fi',
  appStoreUrl: '',
  googlePlayUrl: '',
  googleReviewUrl: '',
  termsAndConditionsUrl: {
    en: 'https://www.kotipuhtaaksi.fi/en/terms-and-conditions',
    fi: 'https://www.kotipuhtaaksi.fi/ehdot',
  },
  privacyPolicyUrl: {
    en: 'https://www.kotipuhtaaksi.fi/en/privacy-policy',
    fi: 'https://www.kotipuhtaaksi.fi/tietosuojaseloste',
  },
  freskaAppUrl: {
    en: '',
    fi: '',
  },
};

export default config;
