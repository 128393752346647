import { createFreskaTheme } from '@freska-fi/ui-theme';

import FuturaBT from './assets/fonts/FuturaBT-CondExtraBlack.otf';
import { getBrand } from './utilities/brand';

export const createTheme = () => {
  const theme = createFreskaTheme();

  if (getBrand() === 'kp') {
    theme.palette.primary.light = '#fcb969';
    theme.palette.primary.main = '#fa8b06';
    theme.palette.primary.dark = '#e27d05';

    theme.components = {
      ...theme.components,
      MuiFilledInput: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.common.white,
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '8px',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            '&::before': {
              display: 'none',
            },
            '&::after': {
              display: 'none',
            },
            '&:hover': {
              backgroundColor: `${theme.palette.common.white} !important`,
            },
            '&.Mui-focused': {
              backgroundColor: theme.palette.common.white,
              borderColor: theme.palette.primary.main,
            },
            '&.Mui-disabled': {
              background: 'transparent !important',
              borderColor: 'transparent',
              cursor: 'not-allowed',
            },
            '&.Mui-error': {
              borderColor: theme.palette.error.main,
            },
          },
          multiline: {
            lineHeight: 1.33,
            paddingTop: 16,
            paddingBottom: 16,
          },
        },
      },
    };
  }

  theme.components = {
    ...theme.components,
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Futura BT';
          src:  url(${FuturaBT}) 
        }
      `,
    },
  };

  return theme;
};
